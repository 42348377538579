/**  =====================
      button css start
==========================  **/
.btn-page {
    .btn {
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .btn-group,
    .btn-group-vertical {
        .btn {
            margin-right: 0;
            margin-bottom: 0;
        }
    }
}

.btn.btn-icon {
    width: 45px;
    height: 45px;
    padding: 0;
    border-radius: 50%;
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.btn-flat{

}

.btn-link {
    font-weight: 400;
    color: black;
    text-decoration: none;
}

.btn-link.active {
    font-weight: 600;
    color: black;
    text-decoration: none;
}

.btn-link:hover{
    text-decoration: none;
}

.btn-link i {
    color: black !important;
    margin-right: 5px;
    font-size: 17px !important;
}

.btn-primary {
    color: #fff !important;
    text-decoration: none !important;
}
