.tab-user-card {
  .nav-pills {
    background: #ecf0f5;
    padding: 20px;
    border-radius: 5px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active{
  background: $primary-color;
  border-color: $primary-color;
}

.q-view{
  width: 100vw;
  left: 0;
  &.active{
    .content {
      left: auto;
      position: fixed;
      .media-object {
        margin-bottom: 10px;
      }
    }
  }
}

.help-desk {
  .media-object {
    margin-bottom: 10px;
  }
}

.navbar-wrapper {
  .navbar-content {
    &.next-scroll {
      height: 100vh;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .hover-blk {
    display: none;
  }
}

.qview-modal {
  &.fade {
    .q-view-modal.modal-dialog {
      transform: translateX(550px);
    }
  }
  &.show {
    padding-right: 0 !important;
    .q-view-modal.modal-dialog {
      transform: none;
    }
  }
}
.q-view-modal {
  margin: 0 0 0 auto !important;
  .modal-content {
    min-height: 100vh;
    border-radius: 0;
    border: none;

    .modal-body {
      flex: 1 1 100%;
      height: 100%;

      .trumbowyg-box,
      .trumbowyg-editor {
        min-height: 110px;
      }
    }
  }
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 550px;
      margin: 0 0 0 auto;
    }
  }
}
.pcoded-header .dropleft .dropdown-toggle::before {
  display: none;
}

input.form-control[type='radio']{
  height: 25px;
}

.alert-danger {
  color: #E62355;
  background-color: #ffdcdc;
  border-color: #ffcfcf;
}

.bg-green {
  background-color: #179FA6
}

.btn-primary {
  font-weight: 600;
  padding: 5px 20px;
}

.bootstrap-tagsinput {
  min-height: 50px !important;
  display: inline-block !important;
}

.card-small-padding {
  padding: 10px 20px !important;
}

// SMS AND EMAIL CODES
.code-container {
  height: 60px;
  width: 400px;
}

.code-character {
  line-height: 60px;
  font-size: 36px;
  font-weight: 600;
  color: #504F4A;
  background-color: #ECF0F5;
  border: 2px solid #5E6EA7;
  border-radius: 4px;
  margin-left: 8px;
}

.code-character:first-child {
  margin-left: 0;
}

.code-character--inactive {
  background-color: #ECF0F5;
}

.code-character--selected {
  border: 2px solid #95C567;
}

// SMS AND EMAIL CODES

@media only screen and (max-width: 768px) {
  td, th {
    white-space: pre-line !important;
    // max-width: 80px;
  }

  .code-container {
    max-width: 300px;
  }
}

@media only screen and (min-width: 768px) {
  .auth-content {
    width: 1200px !important;
  }
}

.react-toast-notifications__container {
  z-index: 1000000 !important;
}

.table-history thead tr {
  background-color: #5d6ea7;
  border: none;
  font-weight: 700;
  color: white;
}

.table-history thead td {
  border: 1px solid #5d6ea7;
}

.table-history {

  * {
    box-sizing: content-box;
  }

  @media (max-width: 640px) {
    span.badge {
      display: block;
      width: 100px;
      white-space: normal;
    }
  }

}

.table-history td, .table-history th {
  padding: 10px;
  border: none;
}

.table-history tbody tr {
  margin-bottom: 20px;
}

.table-history tbody tr:hover {
  background-color: #4d4d4f;
  color: white;
  cursor: pointer;
}

.details-table td, .details-table th {
  border: none;
  padding: 10px;
}


@media only screen and (min-width: 768px) {
  .menu-items {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .menu-items  {
    flex-wrap: wrap;
    margin: 1rem 0;
  }
  .menu-items .btn {
    padding: 0.8rem;
    width: 100%;
  }
}

.appear-from-left {
  animation: appearFromLeft 1s;
  @keyframes appearFromLeft {
    from {
      opacity: 0;
      transform: translateX(-100px);
    }
    to{
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.appear-from-right {
  animation: appearFromRight 1s;
  @keyframes appearFromRight {
    from {
      opacity: 0;
      transform: translateX(100px);
    }
    to{
      opacity: 1;
      transform: translateX(0);
    }
  }
}
